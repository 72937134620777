
import { Options, Vue } from 'vue-class-component';
import { UserClient } from '@/services/Services';
import * as OM from '@/model';
import store from '@/store';
import ModalSuccessError from '@/components/modalSuccessError.vue';
import { LoginServices } from '@/services/LoginServices';
import { getUniversalDate, getUniversalISOStringDate } from '@/utils';

@Options({
    components: {
    }
})
export default class CompleteProfile extends Vue {
    
    user: OM.CompleteProfileVM = new OM.CompleteProfileVM();
    responseEmail: string = "";
    loading: boolean = false;

    created(){
        UserClient.getProfileVM()
        .then( x => {
            this.user = x;
            
            if(x.birthDate){
                var date = new Date(x.birthDate);
                this.user.birthDate = getUniversalISOStringDate(date).substring(0, 10);
            }

            this.responseEmail = x.email;
        })
    }

    changeDate(){
        if(!this.user.birthDate)
            this.user.birthDate = getUniversalISOStringDate(new Date()).substring(0, 10);
    }

    completeProfile(){
        this.loading = true;
        UserClient.completeProfile(this.user)
        .then( x => {
            store.state.user.name = this.user.name;
            if(store.state.user.firstSetupDone){
                if(this.$route.query.redirect){
                    this.$router.replace(this.$route.query.redirect as string);
                } else {
                    this.$router.replace('/home');
                }
            } else {
                this.$router.replace({
                    path: '/categories/false',
                    query: this.$route.query
                });
            }
        })
        .catch( error => {
            this.$opModal.show(ModalSuccessError, 
            {
                isError: true,
                message: error.message
            });
        }).finally( () => {
            this.loading = false;
        })
    }

    back(){
        LoginServices.logout();
    }

    get isDisabled(){
        return !this.user.name || !this.user.surname || !this.user.birthDate;
    }

}
